import { buildRoutesMap, route } from 'redux-saga-first-router';
import { navigateCoronaDemo } from './app/Demo/demoSagas';
import { freeTrial, navigateFreeTrial, navigateHome, navigateWelcomeBack } from './app/FreeTrial/freeTrialSagas';
import { navigateMayaPleTrial, navigateNotEligible } from './app/MayaPle/mayaPleSagas';
import { navigateBeta } from './app/Beta/betaSagas';

const betaRoutes = 'beta-example|envision';
const trialRoutes = 'free-trial|chaos-cloud|chaos-player|chaos-vantage|enscape-trial|enscape-impact';
const languages = 'en|es|pt|cn|kr|jp|de|it|fr';

export default buildRoutesMap(
  route('CORONA_DEMO', '/activate', navigateCoronaDemo),
  route('CORONA_DEMO_SUCCESS', '/activate/success'),

  route('FREE_TRIAL_WELCOME_BACK', `/:lang(${languages})?/:product(${trialRoutes})/welcome-back`, () =>
    freeTrial(navigateWelcomeBack),
  ),
  route(
    'FREE_TRIAL',
    `/:lang(${languages})?/:product(${trialRoutes})/:step(phone|verify|role|tool-of-choice|success|enscape-impact)`,
    () => freeTrial(navigateFreeTrial),
  ),
  route('FREE_TRIAL_HOME', `/:lang(${languages})?/:product(${trialRoutes})`, () => freeTrial(navigateFreeTrial)),
  route('MAYA_PLE', `/:lang(${languages})?/maya-ple`, navigateMayaPleTrial),
  route('MAYA_PLE_SUCCESS', `/:lang(${languages})?/maya-ple/success`),
  route('MAYA_PLE_WELCOME_BACK', `/:lang(${languages})?/maya-ple/welcome-back`, navigateNotEligible),
  route('BETA', `/:lang(${languages})?/beta/:product(${betaRoutes})`, navigateBeta),
  route('BETA_SUCCESS', `/:lang(${languages})?/beta/:product(${betaRoutes})/success`),
  route('BETA_SUCCESS_ENVISION', `/:lang(${languages})?/beta/:product(${betaRoutes})/success`),
  route('BETA_WELCOME_BACK', `/:lang(${languages})?/beta/:product(${betaRoutes})/welcome-back`),
  route('HOME', `/:lang(${languages})?`, navigateHome),
);
