import React from 'react';
import { connect } from 'react-redux';
import i18n from 'i18next';
import Demo from '../Demo';
import DemoSuccess from '../Demo/Success';
import FreeTrial from '../FreeTrial';
import WelcomeBack from '../FreeTrial/WelcomeBack';
import ChaosLayout from './ChaosLayout';
import CoronaDemoLayout from './CoronaDemoLayout';
import MayaPleLayout from './MayaPleLayout';
import MayaPle from '../MayaPle';
import MayaPleNotEligible from '../MayaPle/NotEligible';
import MayaPleSuccess from '../MayaPle/Success';
import Beta from '../Beta/Envision/envision';
import BetaSuccess from '../Beta/Success';
import BetaNotEligible from '../Beta/NotEligible';
import { getMainDomain, LANGUAGE_COOKIE, setCookie } from '../Utils/cookiesHelper';
import SuccessEnvision from '../Beta/Envision/SuccessEnvision';

const routesComponentsMap = {
  FREE_TRIAL_HOME: () => (
    <ChaosLayout>
      <FreeTrial />
    </ChaosLayout>
  ),
  FREE_TRIAL: () => (
    <ChaosLayout>
      <FreeTrial />
    </ChaosLayout>
  ),
  MAYA_PLE: () => (
    <MayaPleLayout>
      <MayaPle />
    </MayaPleLayout>
  ),
  MAYA_PLE_WELCOME_BACK: () => (
    <MayaPleLayout>
      <MayaPleNotEligible />
    </MayaPleLayout>
  ),
  MAYA_PLE_SUCCESS: () => (
    <MayaPleLayout>
      <MayaPleSuccess />
    </MayaPleLayout>
  ),
  FREE_TRIAL_WELCOME_BACK: () => (
    <ChaosLayout>
      <WelcomeBack />
    </ChaosLayout>
  ),
  CORONA_DEMO: () => (
    <CoronaDemoLayout>
      <Demo />
    </CoronaDemoLayout>
  ),
  CORONA_DEMO_SUCCESS: () => (
    <CoronaDemoLayout>
      <DemoSuccess />
    </CoronaDemoLayout>
  ),
  BETA: () => (
    <MayaPleLayout>
      <Beta />
    </MayaPleLayout>
  ),
  BETA_WELCOME_BACK: () => (
    <MayaPleLayout>
      <BetaNotEligible />
    </MayaPleLayout>
  ),
  BETA_SUCCESS: () => (
    <MayaPleLayout>
      <BetaSuccess />
    </MayaPleLayout>
  ),
  BETA_SUCCESS_ENVISION: () => (
    <MayaPleLayout>
      <SuccessEnvision />
    </MayaPleLayout>
  ),
};

const App = ({ routing, auth }) => {
  if (!routing.id || !auth.UserGUID) {
    return null;
  }

  // Change language if address is with language segment and set lang cookie
  if (routing.params.lang) {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + 1);
    setCookie(LANGUAGE_COOKIE, routing.params.lang, getMainDomain(window.location.host), currentDate.toUTCString());
    i18n.changeLanguage(routing.params.lang);
  }

  if (!routesComponentsMap[routing.id]) {
    routesComponentsMap[routing.id] = () => <>Not found</>;
  }

  return <>{routesComponentsMap[routing.id]()}</>;
};

export default connect((state) => ({
  routing: state.routing,
  auth: state.auth,
}))(App);
