import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { OCCUPATION_COMPONENTS } from './occupations';

const Occupation = ({ occupations, shouldSelectOnClick, onOccupationSelect, showContinue = true, t }) => {
  const [selected, setSelected] = useState('');
  const occupationsValues = occupations || Object.keys(OCCUPATION_COMPONENTS);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="content occupation">
      <p>{t('What is your current occupation?')}</p>
      <br />
      <div className="form-field-wrapper box-buttons">
        {occupationsValues.map((occupation) => {
          const isSelected = occupation === selected;
          const className = isSelected ? 'box-button selected' : 'box-button';
          return (
            <div
              key={occupation}
              className={className}
              onClick={() => {
                setSelected(occupation);
                if (shouldSelectOnClick) {
                  onOccupationSelect(occupation);
                }
              }}
            >
              <img alt={occupation} src={`/images/trial/occupation/${occupation}.svg`} />
              {t(occupation)}
            </div>
          );
        })}
      </div>
      {showContinue && (
        <>
          <br />
          <br />
          <div className="centered">
            <button type="button" className="button" onClick={() => onOccupationSelect(selected)} disabled={!selected}>
              {t('Continue')}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default withTranslation()(Occupation);
