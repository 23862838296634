import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Occupation from '../../Shared/Form/Occupation';
import Loading from '../../App/Loading';
import {
  MAYA_PLE_CREATE,
  MAYA_PLE_GET_DETAILS,
  MAYA_PLE_SELECT,
  MAYA_PLE_SET_DETAILS_CONFIRMED,
  MAYA_PLE_SET_STEP,
} from '../constants';

import Educator from './Educator';
import Individual from './Individual';
import Student from './Student';
import Privacy from '../Privacy';

const getComponent = (occupation, props) => {
  switch (occupation) {
    case 'educator':
      return <Educator {...props} />;
    case 'individual':
      return <Individual {...props} />;
    case 'student':
      return <Student {...props} />;
    default:
      return null;
  }
};

const InitialApplication = ({
  shouldConfirm,
  selected,
  onOccupationSelect,
  onSubmit,
  confirmDetails,
  getDetails,
  setStep,
  t,
}) => {
  const [canContinue, setCanContinue] = useState(false);

  useEffect(() => {
    if (shouldConfirm && !selected) {
      getDetails();
    }
  }, [shouldConfirm]);
  useEffect(() => {
    if (!shouldConfirm) {
      if (selected && selected.using_as) {
        setStep(1);
      } else {
        setStep(0);
      }
    }
  }, [selected]);

  if (shouldConfirm && !selected) {
    return <Loading />;
  }

  if (!selected || !selected.using_as) {
    const occupations = ['educator', 'individual', 'student'];
    return (
      <>
        <Occupation occupations={occupations} onOccupationSelect={onOccupationSelect} />
        {!shouldConfirm && (
          <>
            <br />
            <Privacy />
          </>
        )}
      </>
    );
  }

  return (
    <>
      {getComponent(selected.using_as, { setCanContinue })}
      <br />
      <br />
      <div className="centered">
        <button
          type="button"
          className="button"
          onClick={shouldConfirm ? confirmDetails : onSubmit}
          disabled={!canContinue}
        >
          {shouldConfirm ? t('Confirm') : t('Submit')}
        </button>
      </div>
      <br />
      <div className="centered">
        <a className="back-button" onClick={() => onOccupationSelect(null)}>
          &lt; {shouldConfirm ? t('Change occupation') : t('Back')}
        </a>
      </div>
      {!shouldConfirm && (
        <>
          <br />
          <Privacy />
        </>
      )}
    </>
  );
};

export default connect(
  (state) => ({
    selected: state.mayaPle.selected,
  }),
  (dispatch) => ({
    onOccupationSelect: (occupation) => dispatch({ type: MAYA_PLE_SELECT, data: { using_as: occupation } }),
    onSubmit: () => dispatch({ type: MAYA_PLE_CREATE }),
    getDetails: () => dispatch({ type: MAYA_PLE_GET_DETAILS }),
    confirmDetails: () => dispatch({ type: MAYA_PLE_SET_DETAILS_CONFIRMED, confirmed: true }),
    setStep: (step) => dispatch({ type: MAYA_PLE_SET_STEP, step }),
  }),
)(withTranslation()(InitialApplication));
